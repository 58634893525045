import Header from './components/Header/Header';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Pricing from './components/Pricing/Pricing';
import HowItWorks from './components/HowItWorks/HowItWorks';
import './App.css';
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <div className="App">
      <Analytics />
      <Navbar />
      <Header />
      <HowItWorks />
      <Pricing />
      <Footer />    
    </div>
  );
}

export default App;
