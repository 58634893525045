// Pricing.js
import React from 'react';
import './Pricing.css';
import xIconB from '../../assets/images/x.svg'
import xIconW from '../../assets/images/x_w.svg'
import checkIconB from '../../assets/images/check.svg'
import checkIconW from '../../assets/images/check_w.svg'

const Pricing = () => {
  return (
    <div className="pricing-container">
      <div className='mobile-pricing-title'>
        <span>
          <h1>PRICING</h1>
          <p>CANCEL ANYTIME!</p>
        </span>
      </div>
      <table class="pricing-table">
        <thead>
          <tr>
            <th>
              <span>
                <h1>PRICING</h1>
                <p>CANCEL ANYTIME!</p>
              </span>
            </th>
            <th>
              <span className="checks-cols">
                <p>
                  $1.5K
                </p>
                <span>
                  /MONTH
                </span>
                <a className='med-button' href='https://buy.stripe.com/28o289clQdgy04UfYY' target='_blank' rel="noreferrer">
                  JOIN
                </a>
              </span>
            </th>
            <th>
              <span className="checks-cols">
                <p>
                  $3K
                </p>
                <span>
                  /MONTH
                </span>
                <a className='med-button' href='https://buy.stripe.com/bIYbIJ5Xs1xQ9FueUZ' target='_blank' rel="noreferrer">
                  JOIN
                </a>
              </span>
            </th>
            <th>
              <span className="checks-cols">
                <p>
                  $4.5K
                </p>
                <span>
                  /MONTH
                </span>
                <a className='med-button' href='https://buy.stripe.com/3csdQR3Pk4K25pe004' target='_blank' rel="noreferrer">
                  JOIN
                </a>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span>Posts</span>
            </td>
            <td>
              <span className='text-cell'>
                <span>Up to 20</span>
              </span>
            </td>
            <td>
              <span className='text-cell'>
                <span>Up to 16</span>
              </span>
            </td>
            <td>
              <span className='text-cell'>
                <span>Up to 20</span>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Creative Direction</span>
            </td>
            <td>
              <span>
                <span>
                  <img src={xIconW} alt="x icon" />
                </span>
              </span>
            </td>
            <td>
              <span>
                <span>
                  <img src={checkIconB} alt="x icon" />
                </span>
              </span>
            </td>
            <td>
              <span>
                <span>
                  <img src={checkIconW} alt="x icon" />
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Content Delivery</span>
            </td>
            <td>
              <span className='text-cell'>
                <span>By Request</span>
              </span>
            </td>
            <td>
              <span className='text-cell'>
                <span>Content Bucket</span>
              </span>
            </td>
            <td>
              <span className='text-cell'>
                <span>Content Calendar</span>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Captions</span>
            </td>
            <td>
              <span>
                <span>
                  <img src={xIconW} alt="x icon" />
                </span>
              </span>
            </td>
            <td>
              <span>
                <span>
                  <img src={xIconB} alt="x icon" />
                </span>
              </span>
            </td>
            <td>
              <span>
                <span>
                  <img src={checkIconW} alt="x icon" />
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td><span>Production Days</span></td>
            <td>
              <span className='text-cell'>
                <span>
                  <img src={xIconW} alt="x icon" />
                </span>
              </span>
            </td>
            <td>
              <span className='text-cell'>
                <span>1 /month</span>
              </span>
            </td>
            <td>
              <span className='text-cell'>
                <span>1-2 /month</span>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Graphic Design</span>
            </td>
            <td>
              <span>
                <span>
                  <img src={checkIconW} alt="x icon" />
                </span>
              </span>
            </td>
            <td>
              <span>
                <span>
                  <img src={checkIconB} alt="x icon" />
                </span>
              </span>
            </td>
            <td>
              <span>
                <span>
                  <img src={checkIconW} alt="x icon" />
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Meetings</span>
            </td>
            <td>
              <span>
                <span>
                  <img src={xIconW} alt="x icon" />
                </span>
              </span>
            </td>
            <td>
              <span>
                <span>
                  <img src={checkIconB} alt="x icon" />
                </span>
              </span>
            </td>
            <td>
              <span>
                <span>
                  <img src={checkIconW} alt="x icon" />
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Campaigns</span>
            </td>
            <td>
              <span>
                <span>
                  <img src={xIconW} alt="x icon" />
                </span>
              </span>
            </td>
            <td>
              <span>
                <span>
                  <img src={xIconB} alt="x icon" />
                </span>
              </span>
            </td>
            <td>
              <span>
                <span>
                  <img src={checkIconW} alt="x icon" />
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Key Visual Adaptations</span>
            </td>
            <td>
              <span>
                <span>
                  <img src={xIconW} alt="x icon" />
                </span>
              </span>
            </td>
            <td>
              <span>
                <span>
                  <img src={xIconB} alt="x icon" />
                </span>
              </span>
            </td>
            <td>
              <span>
                <span>
                  <img src={checkIconW} alt="x icon" />
                </span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Pricing;
