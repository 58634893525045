// Header.js
import React from 'react';
import './Header.css';

const Header = () => {
    return (
        <>
            <div className="header-container">
                <h2>SUBSCRIPTION-BASED CONTENT CREATION</h2>
                <p><strong>no</strong> contracts, <strong>cancel</strong> anytime.</p>
                <div></div>
            </div>
            <div className="subheader-container">
                <p>Two experienced Creative Directors creating your brand’s social presence.</p>
                <a className='big-button' href='https://cal.com/endmarks.co' target="_blank" rel="noreferrer">
                    BOOK YOUR CALL
                </a>
            </div>
        </>
    );
};

export default Header;
