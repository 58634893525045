// Header.js
import React from 'react';
import './Navbar.css';
import endmarksLogo from '../../assets/images/endmarks_logo.svg'

const Navbar = () => {
  return (
    <div className="navbar-container">
      <img src={endmarksLogo} alt="company logo" />
    </div>
  );
};

export default Navbar;
