// Footer.js
import React from 'react';
import './Footer.css';
import endmarksLogo from '../../assets/images/emco_logo.svg'
import instagram from '../../assets/images/insta.svg'

const Footer = () => {
  return (
    <>
      <div className="cta-container">
        <p>Not sure we’re the right fit? Try us out for one month at $2,500!</p>
        <a className='big-button' href='https://buy.stripe.com/4gwcMNadI3FY8BqbIO' target="_blank" rel="noreferrer">
          Try us out
        </a>
      </div>

      <div className="footer-container">
        <img src={endmarksLogo} alt="company logo" />
        <div>
          <span>hello@endmarks.co</span>
          <span>endmarks LLC ©2024</span>
          <span>
            <a href='https://www.instagram.com/endmarks.co' target="_blank" rel="noreferrer">
              <img className="insta-icon" src={instagram} alt="" />
              <span className='insta-name'>endmarks.co</span>
            </a>
          </span>
        </div>
      </div>
    </>
  );
};

export default Footer;
