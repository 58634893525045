// HowItWorks.js
import React from 'react';
import './HowItWorks.css';

const HowItWorks = () => {
  return (
    <div className="howitworks-container">
      <h1>HOW IT WORKS</h1>
      <div>
        <span>1</span>
        <h2>MEETING & SUBSCRIPTION</h2>
        <p>Book a meeting and subscribe to one of our plans to get started.</p>
      </div>
      <div>
        <span>2</span>
        <h2>GET THE CONTENT</h2>
        <p>We’ll deliver a month worth of content, monthly.</p>
      </div>
      <div>
        <span>3</span>
        <h2>FEEDBACK</h2>
        <p>Unlimited revisions until you’re completely satisfied.</p>
      </div>
    </div>
  );
};

export default HowItWorks;
